import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Switch, Button } from '@mui/material';
import LatestRelease from './LatestRelease';
import CuratedContent from './CuratedContent';
import RingIcon from './RingIcon';
import BraceletIcon from './BraceletIcon';

import NewsLetterPopUp from './NewsLetterPopUp';


const Home = ({ isMobile }) => {
	const navigate = useNavigate();
    const videoRef = useRef(null);
    const [accessibilityState, setAccessibilityState] = useState(true); // when true video plays

    

    const handleChange = (event) => {
        setAccessibilityState(!accessibilityState);
    };

    useEffect(() => {
        if (accessibilityState) {
            playVideo();
        } else {
            pauseVideo();
        }
    },[accessibilityState]);

    const playVideo = () => {
        if (videoRef.current) {
            videoRef.current.play();
        }
    };

    const pauseVideo = () => {
        if (videoRef.current) {
            videoRef.current.pause();
        }
    };

    return (
        <>
            <NewsLetterPopUp />
            {isMobile ? (
                <div>
                    <Box sx={{ position: "relative", width: '100%', height: '90vh', minHeight:"120vw" }}>
                        <Box component="video" autoPlay muted loop preload="auto" ref={videoRef}
                            sx={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                position: 'absolute', // Ensure video is positioned within the container
                                top: 0,
                                left: 0,
                        }}>
                            <source src="/videos/Ski_promo_silver_riot_v2.mp4" type="video/mp4" />
                        </Box>
                        <Box sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                textAlign: 'center',
                                width: '100%',
                                height: '100%',
                                display: "flex",
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                        }}>
                            <Typography variant="h4" sx={{ color: 'white', fontFamily:"HelveticaNeue", fontSize:"20vw", transform: "scaleX(1.5)" }}>
                                Riot
                            </Typography>
                            <Typography variant="h4" sx={{ color: 'white', fontFamily:"HelveticaNeue", fontSize:"12vw", transform: "scaleX(1.5)" }}>
                                Collection
                            </Typography>
                            <Typography variant="h4" sx={{ color: 'white', fontFamily:"HelveticaNeue", fontWeight:"50", fontSize:"5vw", marginTop:"5vw", transform: "scaleY(1.2)"}}>
                                For those that never quit
                            </Typography>
                            <Button variant="outlined" color="primary" margin="auto" 
                                sx={{
                                    width: '60%',
                                    padding: '2%',
                                    margin: '10vw 15vw 0 15vw',
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '12px',
                                    textTransform: 'none',
                                    fontFamily:"HelveticaNeue",
                                    fontSize:"6vw",
                                    '&:hover': {
                                        border: '2px solid white',
                                    },
                                }}
                                onClick={() => {
                                    navigate('/shop');
                                }}
                            >
                                Shop Now
                            </Button> 
                        </Box>
                    </Box>

                    <img src='/images/BannerBracelet.png' alt='Riot Bracelet promo' style={{ width: '100%', height: '100%', margin:"0 0 -4px 0" }}/>
                    <img src='/images/BannerRing.webp' alt='Fein Ring promo' style={{ width: '100%', height: '100%', margin:"0 0 -4px 0" }}/>
                    
                    <LatestRelease accessibilityState={accessibilityState} isMobile={isMobile} />
                    
                    <Box sx={{ 
                        bgcolor: '#0B0A0A', 
                        padding: '15px',
                        
                    }}>
                        <Typography variant="h4" sx={{color:'white', textAlign: 'left', fontFamily: 'HelveticaNeue', fontWeight:'50', paddingBottom:'8px'}}>
                            Browse By Category
                        </Typography>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            padding: '5vw 0',
                        }}>
                            <Button variant="outlined" color="primary" margin="auto" 
                                sx={{
                                    width: '40vw',
                                    height: '40vw',
                                    padding: '2%',
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '12px',
                                    textTransform: 'none',
                                    fontFamily:"HelveticaNeue",
                                    fontSize:"6vw",
                                    '&:hover': {
                                        border: '2px solid white',
                                    },
                                flexDirection: 'column',
                                }}
                                onClick={() => {
                                    navigate('/shop', { state: { filter: "ring" }, replace: true});
                            }}>
                                <RingIcon sx={{ fontSize: '20vw', color: 'white', }}/>
                                Rings
                            </Button> 
                            <Button variant="outlined" color="primary" margin="auto" 
                                sx={{
                                    width: '40vw',
                                    height: '40vw',
                                    padding: '2%',
                                    color: 'white',
                                    border: '2px solid white',
                                    borderRadius: '12px',
                                    textTransform: 'none',
                                    fontFamily:"HelveticaNeue",
                                    fontSize:"6vw",
                                    '&:hover': {
                                        border: '2px solid white',
                                    },
                                flexDirection: 'column',
                            }}
                                onClick={() => {
                                    navigate('/shop', { state: { filter: "bracelet" }, replace: true});
                                }}
                                >
                                <BraceletIcon sx={{ fontSize: '20vw', color: 'white', }}/>
                                Bracelets
                            </Button>
                        </Box>
                    </Box> 

                    <Box sx={{ 
                        bgcolor: '#0B0A0A', 
                        paddingY: '8px',
                        borderTop: '1px solid white',
                        borderBottom: '1px solid white',
                    }}>
                        <Typography variant="h5" sx={{color:'white', textAlign: 'center', fontFamily: 'Inter, sans-serif', fontWeight:'200', letterSpacing: '.05em', borderBottom: '1px solid white', paddingBottom:'8px'}}>
                            CURATED CONTENT
                        </Typography>
                        <CuratedContent accessibilityState={accessibilityState} />
                    </Box> 
                </div>
            ) : (
                <div style={{
                    position: 'relative',
                }}>
                    <Box component="video" autoPlay muted loop preload="auto"
                    sx={{ 
                        width: '100%', 
                        // minHeight: '740px', 
                        height:'90vh',
                        objectFit: 'cover',
                        margin: '0 0 -4px 0',
                    }} ref={videoRef}>
                        <source src="/videos/Ski_promo_silver_riot_v2.mp4" type="video/mp4" />
                    </Box>
                    <Box sx={{
                        position: 'absolute',
                        top: '100px',
                        right: '50px',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    }}>
                        <Switch checked={accessibilityState} onChange={handleChange} color="primary" />
                    </Box>
                    <LatestRelease accessibilityState={accessibilityState} isMobile={isMobile} />
                    <Box sx={{ 
                        bgcolor: '#0B0A0A', 
                        paddingY: '8px',
                        borderTop: '1px solid white',
                        borderBottom: '1px solid white',
                    }}>
                        <Typography variant="h5" sx={{color:'white', textAlign: 'center', fontFamily: 'Inter, sans-serif', fontWeight:'200', letterSpacing: '.05em', borderBottom: '1px solid white', paddingBottom:'8px'}}>
                            CURATED CONTENT
                        </Typography>
                        <CuratedContent accessibilityState={accessibilityState} />
                    </Box>           

                </div>
            )}
        </>
    );
};

export default Home;
