import Cookies from 'js-cookie';

// Add an item to the cart
function addToCart(item) {
	// Get the current cart
	const cart = getCart();
	console.log(cart, 'add called')
	console.log(item, 'item')
	// Add the new item
	cart.push(item);

	// Save the updated cart
	setCart(cart);
	return cart;
}

// Get the current cart
function getCart() {
  // Parse the cart data from the cookie
  const cart = Cookies.get('cart');
  return cart ? JSON.parse(cart) : [];
}

// Save the cart
function setCart(cart) {
  // Stringify the cart data and save it in the cookie
  Cookies.set('cart', JSON.stringify(cart));
}

// Remove an item from the cart
function removeFromCart(item) {
    // Get the current cart
    let cart = getCart();
    console.log(cart, 'remove called')
    console.log(item, 'item')
    try {
	  	// Remove the item
    	const index = cart.findIndex(i => i === item);
		console.log(cart[index], 'val to remove')
		console.log(index, 'index')
		if (index !== -1) {
			cart.splice(index, 1);
		}
	} catch (e) {	
		console.error('cannot remove item from cart', e);
	}
    // Save the updated cart
    setCart(cart);
	return cart;
}

function clearCart() {
  // Clear the cart data from the cookie
  Cookies.remove('cart');
  
}

function getCookie(name) {
  let cookieValue = null;
  if (document.cookie && document.cookie !== '') {
    let cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === (name + '=')) {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
}

function checkCookie(name) {
  const cookieString = decodeURIComponent(document.cookie);
  console.log(cookieString, 'cookieString utils')
  const cookies = cookieString.split(';');
  for(let i = 0; i < cookies.length; i++) {
    let c = cookies[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return true;
    }
  }
  return false;
}

function getPopUpCookie() {
  // Check if the popup cookie exists
  const popup = Cookies.get('popup');
  return !!popup; // Return true if the popup cookie exists, otherwise false
}
function setPopUpCookie() {
  // Stringify the cart data and save it in the cookie
  Cookies.set('popup', "true");
}

export { addToCart, getCart, removeFromCart, setCart, clearCart, getCookie, checkCookie, getPopUpCookie, setPopUpCookie };