import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardActionArea, CardContent, Typography, CardMedia, Grid } from '@mui/material';

const ShopCard = ({ product }) => {
    const { name, price, image_url_0, image_url_1, id, out_of_stock, secondary_name } = product;
    console.log("product", product);
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Card sx={{ position: 'relative', width: '100%', height: '100%', borderRadius: '0', border: '1px solid white'}} //#721E1D
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <CardActionArea 
                component={Link} 
                to={`/product/${id}`}
                sx={{height:'100%'}}
            >
                <CardMedia
                    overflow="hidden"
                    component="img"
                    height="80%"
                    src={isHovered ? '/images/' + image_url_1 : '/images/' + image_url_0} 
                    alt={name}
                />
                {!!out_of_stock && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '20%',
                            marginTop: '40%',
                            backgroundColor: 'rgba(255, 255, 255, 0.9)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography variant="h6" sx={{color:'#333', textAlign: 'center', fontFamily: 'Raleway, sans-serif', fontWeight:'bold', fontSize:"200%" }}>
                            OUT OF STOCK
                        </Typography>
                    </div>
                )}
                
                <CardContent
                    sx={{
                        width: '100%',
                        backgroundColor: '#0B0A0A',
                        height: '20%',
                        color: 'white',
                        textAlign: 'center',
                        padding: "0",
                        borderTop: '1px solid white',
                    }}
                >
                    <Grid container sx={{ height:'100%', display:"flex", alignItems:'center' }}>
                        <Grid item xs={8} sm={9} lg={8} paddingLeft="15px">
                            <Typography variant="h5" sx={{ fontSize:{xs:"7vw", sm:'5vw', md:'5vw', lg:'3vw'}, height:'50%', color:'white', textAlign: 'left', fontFamily: 'HelveticaNeue', fontWeight:'900', textShadow:'1px 1px 1px rgba(0,0,0,.5)', letterSpacing: '0', textTransform:"uppercase", fontStyle:"italic", lineHeight:'1'}}>{name}</Typography>
                            <Typography variant="body1" sx={{ fontSize:{xs:"4vw", sm:'4vw', md:'4vw', lg:'2vw'}, height:'50%', color:'white', textAlign: 'left', fontFamily: 'HelveticaNeue', fontWeight:'900', textShadow:'1px 1px 1px rgba(0,0,0,.5)', letterSpacing: '0', textTransform:"uppercase", fontStyle:"italic", lineHeight:'1'}}>{(secondary_name) ? `[${secondary_name}]` : ''}</Typography>
                        </Grid>
                        <Grid item xs={4} sm={3} lg={4}>
                            <Typography variant="h6" sx={{ fontSize:{xs:"5vw", sm:'4vw', md:'4vw', lg:'3vw'}, height:'66%', color:'white', textAlign: 'left', fontFamily: 'HelveticaNeue', fontWeight:'900', textShadow:'1px 1px 1px rgba(0,0,0,.5)', letterSpacing: '0', textTransform:"uppercase", fontStyle:"italic", lineHeight:'1'}}>£{price}</Typography>
                            <Typography variant="body1" sx={{ fontSize:{xs:"4vw", sm:'4vw', md:'4vw', lg:'2vw'}, height:'33%', color:'#0B0A0A', textAlign: 'left', fontFamily: 'HelveticaNeue', fontWeight:'900', textShadow:'1px 1px 1px rgba(0,0,0,.5)', letterSpacing: '0', textTransform:"uppercase", fontStyle:"italic", lineHeight:'1'}} aria-hidden="true">{secondary_name}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
                
            </CardActionArea>
        </Card>
    );
};

export default ShopCard;