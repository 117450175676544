import axios from "axios";

const filePath =
process.env.NODE_ENV === 'production'
  ? 'https://silverriot.co.uk/api/'
  : 'http://127.0.0.1/api/';

async function get_product(id) {
    let sizesInStock
    let lengths
    let weight
    try {
        const response = await axios.post(filePath + 'get_products.php',{id});
        if (response.data.type == "bracelet") {

            sizesInStock = response.data.sizes_in_stock.split(",").map(Number); 
            lengths = response.data.length.split(",");            
            weight = response.data.weight.split(",");    

            delete response.data.sizes_in_stock;
            delete response.data.length;
            delete response.data.weight;

            const sizes = ["S", "M", "L"];
            response.data.size_info = sizes.map((size, index) => ({
                size: size,
                number_of_stock: sizesInStock[index],
                length: lengths[index],
                weight: weight[index]
            }));

            if (sizesInStock.reduce((acc, curr) => acc + curr, 0) == 0) {
                response.data.out_of_stock = true;
            } else {
                response.data.out_of_stock = false;
            }

            const parts = response.data.manufacturing.split('contact us [link to contact us]');
            response.data.manufacturing = [
                parts[0],
                <a href="/contact" style={{ color: 'white', textDecoration: 'underline' }} key="contact-link">
                    contact us
                </a>,
                parts[1]
            ];
        }
        if (response.data.type == "ring") {

            sizesInStock = response.data.sizes_in_stock.split(",").map(Number); 
            lengths = response.data.length.split(",");            
            weight = response.data.weight.split(",");    

            delete response.data.sizes_in_stock;
            delete response.data.length;
            delete response.data.weight;

            const sizes = ["D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W"];
            response.data.size_info = sizes.map((size, index) => ({
                size: size,
                number_of_stock: sizesInStock[index],
                length: lengths[index],
                weight: weight[index]
            }));

            if (sizesInStock.reduce((acc, curr) => acc + curr, 0) == 0) {
                console.log("out of stock")
                response.data.out_of_stock = true;
                response.data.size_info = response.data.size_info.slice(9, 12)
            } else {
                console.log("in stock")
                response.data.out_of_stock = false;
                response.data.size_info.filter(item => item.number_of_stock > 0);
            }

            const parts = response.data.manufacturing.split('contact us [link to contact us]');
            response.data.manufacturing = [
                parts[0],
                <a href="/contact" style={{ color: 'white', textDecoration: 'underline' }} key="contact-link">
                    contact us
                </a>,
                parts[1]
            ];
        }
        return response.data; 
    } catch (error) {
        console.error(error);
    }
};

async function get_products() {
    try {
        const response = await axios.get(filePath + 'get_products.php',{
            method: 'GET',
            credentials: 'include',
        });
        console.log("teehee", response.data);
        response.data.map((product) => { 
            let sizesInStock = product.sizes_in_stock.split(",").map(Number); 
            console.log(product.name," ", sizesInStock.reduce((acc, curr) => acc + curr, 0), sizesInStock)
            if (sizesInStock.reduce((acc, curr) => acc + curr, 0) == 0) {
                product.out_of_stock = true;
            } else {
                product.out_of_stock = false;
            }
        });
        return response.data;
    } catch (error) {
        console.error('There was an error!', error);
    }
};

export { get_product, get_products };